<template>
    <b-container>
        <b-row>
            <b-col cols="8">
                <h1>Users</h1>
            </b-col>
            <b-col>
                <div @click="edit_user({ id: 0 })" class="btn btn-primary"><font-awesome-icon icon="user-plus" /> Add User</div>
            </b-col>
        </b-row>
            <draggable v-model="sorted_users" handle=".handle">
                <div class="form-group row" v-for="user in sorted_users"  v-bind:key="'edit-user-row-'+user.id">
                    <div class="col-1 handle">
                        <font-awesome-icon icon="grip-lines" />
                    </div>

                    <div class="col-3" >
                        <b>{{user.role}}</b>
                    </div>
                    <div class="col-7" >
                        {{user.firstname}} {{user.middlename}} {{user.lastname}} 
                    </div>                    

                    <div class="col-1">
                        <a href="javascript:;" class="float-right" @click="delete_user(user.id)"><font-awesome-icon icon="trash"/></a>
                        <a href="javascript:;" class="float-right" @click="edit_user(user)"><font-awesome-icon icon="user-edit"/></a>
                    </div>

                </div>
            </draggable> 
    </b-container>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import draggable from 'vuedraggable'
import user_details_modal from './modals/UserModal'

export default {
    name: 'users',
    data() {
        return {
        }
    },
    computed: {
        sorted_users: {
            get: function() {
                return this.users
            },
            set: function(val) {
                //update sort of each user
                val.forEach(user => {
                    user.sort = val.indexOf(user)
                })
                
                //store in Vuex
                this.$store.commit('users', val)

                //save to database
                this.users.forEach(user => {
                    this.save_user(user.id)
                });
            }
        },
        ...mapGetters([
            'createAny',
            'readAny',
            'updateAny',
            'deleteAny',
        ]),
        ...mapState([
            'users',
            'settings'
        ])
    },
    methods:{
        ...mapActions([
            'delete_user',
            'save_user'
        ]),
        sort_role(users) {
            users.sort((a, b) => a.role > b.role && 1 || -1)
        },
        edit_user(user) {
            this.$modal.hide('user-details-modal')
            this.$modal.show(user_details_modal, {
                user_id: user.id,
            }, {
                name: 'user-details-modal',
                draggable: '.popup-header',
                height: 'auto',
                width: '800px'
            })

            this.$emit('close')
        },
    },
    components: { draggable }
}
</script>
